<template>
  <div>
    <div class="menuBox">
      <template v-for="(item, index) in thirdMenu" :key="index">
        <div
          @click="changeTabs(item)"
          :class="['menuItem', activeMenu.path === item.path ? 'isActive' : '']"
        >
          {{ item.meta.name }}
        </div>
      </template>
    </div>
    <div class="xian"></div>
    <router-view></router-view>
  </div>
</template>

<script>
import { reactive, ref, watch, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { post } from '@/util/axios.js'
import { api } from '@/util/api.js'
import { ElMessage } from 'element-plus'
export default {
  async setup() {
    const $route = useRoute()
    const $router = useRouter()
    const thirdMenu = reactive([
      { meta: { name: '客服首句欢迎语' }, path: '/autoReply/serviceReply/welcome' },
      { meta: { name: '客服排队中提醒' }, path: '/autoReply/serviceReply/waiting' },
      { meta: { name: '客服下班后提醒' }, path: '/autoReply/serviceReply/off_work' },
      { meta: { name: '客服常见问题' }, path: '/autoReply/serviceReply/faq' }
    ])
    const activeMenu = ref({})
    activeMenu.value = thirdMenu[0]
    watch(
      $route,
      (val) => {
        activeMenu.value = val
      },
      {
        immediate: true
      }
    )
    const changeTabs = (item) => {
      activeMenu.value = item
      $router.push(`${item.path}`)
    }
    const replyInfo = ref({})
    const commonProblemList = ref([])
    provide('replyInfo', replyInfo)
    provide('commonProblemList', commonProblemList)
    await post(api.getAutomaticReply).then((res) => {
      if (res.code == 0) {
        replyInfo.value = res.result
      } else {
        ElMessage.error(`${res.msg}`)
      }
    })
    await post(api.getProblem, {
      page: 1,
      rows: 10000
    }).then((res) => {
      commonProblemList.value = res.result.list
    })

    return {
      thirdMenu,
      activeMenu,
      changeTabs
    }
  }
}
</script>

<style lang="less" scoped>
.menuBox {
  display: flex;
  .menuItem {
    padding: 14px;
    margin: 0 10px;
    cursor: pointer;
    white-space: nowrap;
  }
  .isActive {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #1467ff;
      position: absolute;
      left: 0;
      bottom: -2px;
    }
  }
}
.xian {
  width: 100%;
  height: 2px;
  background-color: #e8e8e8;
  margin-bottom: 20px;
}
</style>
